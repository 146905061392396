


@import "../../../../css-variables/variables.sass"

.navbar
    background: $main-color
    height: 100vh
    display: flex
    flex-direction: column
    align-items: center
    padding: 15px 0
    position: fixed
    left: 0
    z-index: 2
    width: 12%
    overflow-y: scroll
    min-width: 150px

    &::-webkit-scrollbar 
        width: 5px
    &::-webkit-scrollbar-track
        background: $secondary-color
    &::-webkit-scrollbar-thumb 
        background: $main-color-light

    &::-webkit-scrollbar-thumb:hover
        background: $main-color-light
            
    

    .nav-link-container
        transition: ease-in-out .1s
        padding: 5px
        margin: 5px 0 !important

        &:hover
            background-color: $secondary-color
            border-radius: 5px

    .highlighter
        position: absolute
        width: 75%
        background-color: $secondary-color
        height: 4%
        border-radius: 5px
        z-index: 1


    .branding-container
        border-bottom: 1.5px solid #4164B4
        width: 75%
        display: flex
        padding: 15px 0
        align-items: center
        z-index: 2

        .logo-background
            width: 32px
            height: 32px
            background: white
            border-radius: 50%
            display: flex
            justify-content: center
            align-items: center

            img
                width: 22px
                height: 22px
        h2
            color: white
            margin: 0 0 0 10px
            font-size: 1.125rem

                
    .main-menu, .section
        width: 75%
        z-index: 2

        .nav-category
            font-size: 14px
            color: $secondary-color
            font-weight: 500
            opacity: 75%
            margin: 15px 0 
            position: relative

            .drop-down
                img 
                    width: 20px
                    height: 20px
                    align-self: center
                    position: absolute
                    right: 15%
                    bottom: 0
            
        .nav-link-container
            a
                text-decoration: none
                width: 100%
                display: flex
                align-items: center
                text-transform: capitalize


            h4
                font-size: 14px
                margin: 0 0 0 5%
                color: white
                opacity: 50%
                font-weight: 400
            img
                width: 28px
                height: 28px

.small-nav 
    .nav-info-wrapper 
        margin: 35px 0 0 0

@media screen and (max-width: $laptop-big-breakpoint) 
    .desktop-nav
        // width: 250px
        .drop-down
            img 
                width: 18px !important
                height: 18px !important

        .branding-container
            h2
                font-size: .95rem
        .nav-link-container
            h4 
                font-size: 12px !important
            img 
                width: 24px !important
                height: 24px !important

@media screen and (max-width: $laptop-medium-breakpoint)
    .desktop-nav
        display: none
        .branding-container
            h2
                font-size: .85rem
