@import "../../../../css-variables/variables"
    
.attention
    position: fixed
    width: 40vh
    height: 50vh
    max-height: 500px
    max-width: 350px
    z-index: 5
    display: flex
    justify-content: center
    flex-direction: column
    align-items: center
    gap: 40px
    padding: 0 15%
    border: 2px solid $secondary-color !important

    .img-container
        display: flex
        justify-content: center
        align-items: center
        gap: 15px
    
        img
            width: 50px
            height: 50px
            
        h4
            font-size: 30px
            color: tomato

    .info-container
        h6 
            font-size: 20px
            text-align: center
            color: $main-color
            
