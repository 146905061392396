@import "../../css-variables/variables.sass"
    
.userLayout 
    position: relative
    z-index: 1
    display: flex
    width: 92%
    height: 100vh
    background-color: $main-bg-color
    display: flex
    flex-direction: column
    position: absolute
    right: 0
    overflow-x: hidden

    &::-webkit-scrollbar 
        width: 10px
    &::-webkit-scrollbar-track
        background: #fff
    &::-webkit-scrollbar-thumb 
        background: $main-color
        border: 1px solid black
        border-radius: 5px

    &::-webkit-scrollbar-thumb:hover
        background: $main-color-light

    @media screen and (max-width: $laptop-medium-breakpoint) 
        width: 100% !important


    
