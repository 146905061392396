@import "../../../../../css-variables/variables.sass"


.addLink-modal
    border-radius: 5px
    border: 2px solid $secondary-color !important
    padding: 30px !important
    display: flex
    justify-content: center
    flex-direction: column

    h2
        color: $main-color

    .button-container
        width: 100%
        display: flex
        justify-content: space-around
        margin: 15px 0 0 0

        .accept
            &:hover
                background: $secondary-color
                color: white
        .cancel
            &:hover 
                background: tomato
                color: white
                border: 1px solid tomato

.links-container
    width: 35%
    box-shadow: 8px 8px 5px -3px rgba(158,158,158,0.92)
    -webkit-box-shadow: 8px 8px 5px -3px rgba(158,158,158,0.92)
    -moz-box-shadow: 8px 8px 5px -3px rgba(158,158,158,0.92)
    border-radius: 15px
    background: white
    height: 45vh
    margin: 10px
    display: flex
    flex-direction: column
    align-items: center
    overflow-y: scroll
    overflow-x: hidden
    scrollbar-width: none

    .link-container
        display: flex
        align-items: center
        width: 90%

        .deleteLink
            width: 24px
            height: 24px
            cursor: pointer

    .addLink
        width: 90%
        display: flex
        align-self: center
        display: flex
        align-items: center
        gap: 10px 
        border-bottom: .5px solid $main-bg-color
        margin: 5px 0 0 0
        cursor: pointer

        img 
            width: 22px
            height: 22px
            margin: 1px

        h4 
            color: $main-color
    h2 
        margin: 20px 20px
        color: $main-color
        align-self: flex-start
    .link
        width: 90%
        display: flex
        align-self: center
        display: flex
        align-items: center
        gap: 10px 
        border-bottom: .5px solid $main-bg-color
        margin: 5px 0 0 0

        img 
            width: 24px
            height: 24px    