@import "../../css-variables/variables.sass"

.wave
    z-index: 1
    position: absolute
    bottom: -25% !important


.register-card-container
    width: 30vw
    height: 80vh
    min-width: 500px
    max-width: 650px
    max-height: 1000px
    position: absolute
    top: 0
    bottom: 0
    left: 0
    right: 0
    margin: auto 
    z-index: 2
    border: .5px solid $main-color
    display: flex
    flex-direction: column
    border-radius: 15px
    background: white
    align-items: center
    overflow: hidden

    .loading-container
        position: absolute
        top: 40%
        bottom: 40%
        width: 60px
        height: 60px
        
        svg 
            width: 100%
            height: 100%
            stroke: $main-color-light

    .animation-container
        width: 100%
        height: 100%
        display: flex
        justify-content: center
        align-items: center
        flex-direction: column
        text-align: center

        .registerBtn
            width: 25%
            align-self: center
            background-color: $main-color-light

        .already-member-container
            display: flex
            align-items: center
            margin: 50px 0 0 0

            a 
                font-weight: 700
                color: $main-color-light
                margin: 0 0 0 5px

            h6
                font-size: 0.75rem
                color: $main-color-light
            

        form 
            display: flex
            flex-direction: column
            height: 65%
            width: 60%
            display: flex
            flex-direction: column
            gap: 50px

            .error
                background: #ff000079
                border: 2px solid #ff0000

            .message 
                width: 65%
                position: absolute
                bottom: 28%
                display: flex
                border-radius: 5px
                left: 17.5%
                align-items: center
                justify-content: space-between
                padding: 0 10px

                h6 
                    font-size: 1rem
                    color: white
                    font-weight: 400

                img
                    width: 24px
                    height: 24px
                    margin: 5px 0
                    cursor: pointer

            .input-container
                display: flex
                flex-direction: column
                justify-content: space-evenly
                height: 80%
                position: relative

                .register 
                    margin: 10px 0

                input 
                    outline: $main-color

                a
                    position: absolute
                    bottom: 5%
                    right: 0
                    font-weight: 500
                    color: $main-color-light

            h1
                color: $main-color-light
            

    .branding-container
        display: flex
        align-items: center
        justify-content: center
        position: absolute
        top: 0
        left: 0
        padding: 10px

        h3 
            color: $main-color-light
            font-size: 1.5rem

        img 
            width: 30px
            height: 30px
            margin: 0 10px 0 0


.login-cards-container
    width: 30vw
    height: 80vh
    min-width: 500px
    max-width: 750px
    max-height: 1000px
    position: absolute
    top: 0
    bottom: 0
    left: 0
    right: 0
    margin: auto 
    max-height: 900px

    .loading-container
        position: absolute
        top: 45% 
        right: 45%
        transform: translate(45%,-45%)
        width: 60px
        height: 60px
        z-index: 3
        
        svg 
            width: 100%
            height: 100%
            stroke: $main-color-light

    .card
        display: flex
        justify-content: center
        align-items: center
        height: 98%
        flex-direction: column
        position: absolute
        width: 98%
        border-radius: 15px
        background: white
        border: .5px solid $main-color

        .animation-container
            width: 100%
            height: 100%
            display: flex
            justify-content: center
            align-items: center
            flex-direction: column


    .forgot-pass-container
        position: absolute
        bottom: 0
        right: 0
        box-shadow: 4px 4px 9px -3px rgba(0,0,0,0.75)
        -webkit-box-shadow: 4px 4px 9px -3px rgba(0,0,0,0.75)
        -moz-box-shadow: 4px 4px 9px -3px rgba(0,0,0,0.75)
        z-index: 2

        .forgotpassBtn
            width: 25%
            align-self: center
            background-color: $main-color-light

        .loading-container
            position: absolute
            top: 40%
            bottom: 40%
            width: 60px
            height: 60px
            z-index: 3
            
            svg 
                width: 100%
                height: 100%
                stroke: $main-color-light

        .input-container a
            bottom: 22% !important


    .login-card-container
        box-shadow: -4px -3px 7px -2px rgba(82,82,82,0.75)
        -webkit-box-shadow: -4px -3px 7px -2px rgba(82,82,82,0.75)
        -moz-box-shadow: -4px -3px 7px -2px rgba(82,82,82,0.75)
        position: absolute
        top: 0
        left: 0
        z-index: 2

        .loginBtn
            width: 25%
            align-self: center
            background-color: $main-color-light

    .visible
        z-index: 3

        h1
            color: $main-color-light

        form
            display: flex
            flex-direction: column
            height: 65%
            width: 50%
            display: flex
            flex-direction: column
            justify-content: space-between

            .message
                position: absolute
                border-radius: 5px
                border: 2px solid $main-color-light
                display: flex
                align-items: center
                background: #a8010176

                img 
                    width: 24px
                    height: 24px

            .input-container
                display: flex
                flex-direction: column
                justify-content: space-evenly
                height: 80%
                position: relative
                margin: 100px 0
                gap: 20px
                

                input 
                    outline: $main-color

                a
                    font-weight: 500
                    color: $main-color-light
                

        .branding-container
            display: flex
            align-items: center
            justify-content: center
            position: absolute
            top: 0
            left: 0
            padding: 10px

            h3 
                color: $main-color-light
                font-size: 1.5rem

            img 
                width: 30px
                height: 30px
                margin: 0 10px 0 0

.invalid-register-key
    background: white
    position: absolute
    left:50% 
    top:50% 
    transform: translate(-50%, -50%)
    display: flex
    justify-content: center
    padding: 25px
    box-shadow: -4px -3px 7px -2px rgba(82,82,82,0.75)
    -webkit-box-shadow: -4px -3px 7px -2px rgba(82,82,82,0.75)
    -moz-box-shadow: -4px -3px 7px -2px rgba(82,82,82,0.75)

    .expired-register-info-container
        display: flex
        justify-content: space-between
        align-items: center

        h2 
            color: $secondary-color
            margin: 0 10px

        img 
            width: 24px
            height: 24px

@media screen and (max-height: 900px) 
    .register-card-container
        button, .already-member-container
            margin-top: 50px

@media screen and (max-height: 800px) 
    .register-card-container
        button, .already-member-container
            margin-top: 75px

@media screen and (max-height: 750px) 
    .register-card-container
        button, .already-member-container
            margin-top: 100px

@media screen and (max-height: 650px) 
    .login-cards-container
        width: 100vw
        height: 100vh

@media screen and (max-width: $phone-breakpoint) 
    .register-card-container
        width: 100vw
        height: 100vh
    .login-cards-container
        width: 100vw
        height: 100vh
        display: flex
        align-items: center
        min-width: 100vw
        .card
            width: 100%
            height: 100%
            border-radius: 0
            top: 0 !important
            left: 0 !important

            .animation-container
                align-items: center

                form 
                    width: 75%
                button
                    width: 80%
                    max-width: 250px


