.dashboard-container {
  display: flex;
  flex-direction: column;
  position: relative;
}
.dashboard-container .top-container {
  display: flex;
  width: 100%;
  margin: 80px 0 0 0;
}
.dashboard-container .bottom-container {
  display: flex;
  width: 100%;
}

@media screen and (max-width: 1280px) {
  .top-container {
    flex-direction: column;
  }
  .top-container .recentTraining-container {
    width: 96%;
  }
  .top-container .links-container {
    width: 96%;
  }
  .bottom-container {
    flex-direction: column;
    padding: 0 0 100px 0;
  }
  .bottom-container .frequentlyVisited-container {
    width: 96%;
  }
  .bottom-container .trainingDue-container {
    width: 96%;
  }
}/*# sourceMappingURL=styles.css.map */