@import "../../../../../css-variables/variables.sass"

.toggle-nav
    outline: none
    border: none
    -webkit-user-select: none
    -moz-user-select: none
    -ms-user-select: none
    cursor: pointer
    top: 10px
    left: 10px
    width: 50px
    height: 50px
    border-radius: 50%
    background-color: $main-color !important
    position: fixed !important
    display: flex
    align-items: center
    z-index: 10
    justify-content: center

    path
        stroke: white
    
.small-nav 
    position: fixed
    top: 0
    left: 0
    bottom: 0
    background: #152d64
    display: flex
    flex-direction: column
    z-index: 20
    .nav-info-wrapper 
        background: transparent
        width: 300px !important

@media screen and (min-width: $laptop-medium-breakpoint)
    .toggle-nav
        display: none


@media screen and (max-width: $laptop-medium-breakpoint)
    .small-nav .nav-info-wrapper 
        // display: fixed !important
