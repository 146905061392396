.navbar {
  background: #152D64;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px 0;
  position: fixed;
  left: 0;
  z-index: 2;
  width: 12%;
  overflow-y: scroll;
  min-width: 150px;
}
.navbar::-webkit-scrollbar {
  width: 5px;
}
.navbar::-webkit-scrollbar-track {
  background: #466FFD;
}
.navbar::-webkit-scrollbar-thumb {
  background: #0138b0;
}
.navbar::-webkit-scrollbar-thumb:hover {
  background: #0138b0;
}
.navbar .nav-link-container {
  transition: ease-in-out 0.1s;
  padding: 5px;
  margin: 5px 0 !important;
}
.navbar .nav-link-container:hover {
  background-color: #466FFD;
  border-radius: 5px;
}
.navbar .highlighter {
  position: absolute;
  width: 75%;
  background-color: #466FFD;
  height: 4%;
  border-radius: 5px;
  z-index: 1;
}
.navbar .branding-container {
  border-bottom: 1.5px solid #4164B4;
  width: 75%;
  display: flex;
  padding: 15px 0;
  align-items: center;
  z-index: 2;
}
.navbar .branding-container .logo-background {
  width: 32px;
  height: 32px;
  background: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.navbar .branding-container .logo-background img {
  width: 22px;
  height: 22px;
}
.navbar .branding-container h2 {
  color: white;
  margin: 0 0 0 10px;
  font-size: 1.125rem;
}
.navbar .main-menu, .navbar .section {
  width: 75%;
  z-index: 2;
}
.navbar .main-menu .nav-category, .navbar .section .nav-category {
  font-size: 14px;
  color: #466FFD;
  font-weight: 500;
  opacity: 75%;
  margin: 15px 0;
  position: relative;
}
.navbar .main-menu .nav-category .drop-down img, .navbar .section .nav-category .drop-down img {
  width: 20px;
  height: 20px;
  align-self: center;
  position: absolute;
  right: 15%;
  bottom: 0;
}
.navbar .main-menu .nav-link-container a, .navbar .section .nav-link-container a {
  text-decoration: none;
  width: 100%;
  display: flex;
  align-items: center;
  text-transform: capitalize;
}
.navbar .main-menu .nav-link-container h4, .navbar .section .nav-link-container h4 {
  font-size: 14px;
  margin: 0 0 0 5%;
  color: white;
  opacity: 50%;
  font-weight: 400;
}
.navbar .main-menu .nav-link-container img, .navbar .section .nav-link-container img {
  width: 28px;
  height: 28px;
}

.small-nav .nav-info-wrapper {
  margin: 35px 0 0 0;
}

@media screen and (max-width: 1500px) {
  .desktop-nav .drop-down img {
    width: 18px !important;
    height: 18px !important;
  }
  .desktop-nav .branding-container h2 {
    font-size: 0.95rem;
  }
  .desktop-nav .nav-link-container h4 {
    font-size: 12px !important;
  }
  .desktop-nav .nav-link-container img {
    width: 24px !important;
    height: 24px !important;
  }
}
@media screen and (max-width: 1366px) {
  .desktop-nav {
    display: none;
  }
  .desktop-nav .branding-container h2 {
    font-size: 0.85rem;
  }
}/*# sourceMappingURL=styles.css.map */