.error-container {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 8px 8px 5px -3px rgba(158, 158, 158, 0.92);
  -webkit-box-shadow: 8px 8px 5px -3px rgba(158, 158, 158, 0.92);
  -moz-box-shadow: 8px 8px 5px -3px rgba(158, 158, 158, 0.92);
  border: 2px solid #152D64;
  width: 25%;
  height: 25%;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: space-evenly;
  min-width: 300px;
}
.error-container h1 {
  color: #152D64;
  font-size: 20px;
}
.error-container p {
  color: #0138b0;
}
.error-container button {
  width: 30%;
  min-width: 175px;
}/*# sourceMappingURL=styles.css.map */