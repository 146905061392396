.attention {
  position: fixed;
  width: 40vh;
  height: 50vh;
  max-height: 500px;
  max-width: 350px;
  z-index: 5;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  padding: 0 15%;
  border: 2px solid #466FFD !important;
}
.attention .img-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}
.attention .img-container img {
  width: 50px;
  height: 50px;
}
.attention .img-container h4 {
  font-size: 30px;
  color: tomato;
}
.attention .info-container h6 {
  font-size: 20px;
  text-align: center;
  color: #152D64;
}/*# sourceMappingURL=styles.css.map */