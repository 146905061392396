@import "../../../css-variables/variables.sass"
    
.doc-route-container
    width: 100%
    display: flex
    flex-direction: column
    align-items: center
    padding: 75px 0 0 0

    &:first-child
        margin: 75px 0 0 0

    &:last-child
        margin: 0 0 75px 0 !important

    .admin-doc-section
        position: relative

        .edting-options-container
           button
            margin: 0 10px
        .options-container
            position: absolute
            top: -15px
            right: 0
            margin: 15px
            width: 50px
            height: 50px    

            .options-list-container
                right: 10%
                position: absolute
                width: 250%
                border: 1px solid $secondary-color
                z-index: 6
                border-radius: 5px
                background-color: white
                ul 
                    list-style-type: none
                    li 
                        font-size: 1rem
                        margin: 5px
                        color: $secondary-color
                        font-weight: 500
                        cursor: pointer
            img 
                width: 100%
                height: 100%
                cursor: pointer
            .options
                transform: scale(.75)
                cursor: pointer
        &:hover
            // border: 2px solid $secondary-color
            

    .doc-section-container
        width: 95%
        background: white
        box-shadow: 8px 8px 5px -3px rgba(158,158,158,0.92)
        -webkit-box-shadow: 8px 8px 5px -3px rgba(158,158,158,0.92)
        -moz-box-shadow: 8px 8px 5px -3px rgba(158,158,158,0.92)
        border-radius: 15px
        margin: 15px 0
        padding: 30px
        display: flex
        flex-direction: column
        gap: 10px

        .change-title
            max-width: 250px

        h4
            font-size: 25px
            text-transform: capitalize
            color: $main-color

        .docs-container
            display: flex
            flex-wrap: wrap

            .document-container
                border: 2px solid $secondary-color
                border-radius: 5px
                display: flex
                align-items: center
                cursor: pointer
                height: 50px
                margin: 30px 20px
                padding: 0 10px
                position: relative
                z-index: 5
                text-decoration: none

                h6 
                    font-size: 20px
                    font-weight: 600
                    margin: 0 5px
                    color: $secondary-color
                    text-align: center

                .deleteFIle
                    position: absolute
                    top: -10px
                    right: -10px
                img
                    width: 24px
                    height: 24px

            .deleteDocStyle
                opacity: 50%
    .section-skeleton
        display: flex
        justify-content: center
        align-items: center
        min-height: 25vh
        position: relative

        &:hover
            border: 2px solid $secondary-color

        img 
            width: 50px
            height: 50px
            cursor: pointer
        .options
            position: absolute
            top: -15px
            right: 0
            margin: 15px
            transform: scale(.75)
            cursor: pointer

@media screen and (max-width: $phone-breakpoint)
     .document-container
        width: 100%
        height: auto !important
        min-height: 50px !important

        img 
            justify-self: flex-start !important
            

        h6 
            justify-self: center !important
            align-self: center !important