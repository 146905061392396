// $main-color: #103282
$main-color: #152D64
$secondary-color: #466FFD
$main-bg-color: #d2d2d2
$main-color-light: #0138b0
$laptop-big-breakpoint: 1500px
$desktop-big-height-breakpoint: 1300px
$laptop-small-breakpoint: 1280px
$tablet-medium-breakpoint: 820px
$tablet-small-breakpoint: 700px
$laptop-medium-breakpoint: 1366px
$phone-breakpoint: 600px
