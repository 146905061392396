@import "../../css-variables/variables.sass"
.error-container
    position: absolute
    left: 50%
    top: 50%
    transform: translate(-50%,-50%)
    box-shadow: 8px 8px 5px -3px rgba(158,158,158,0.92)
    -webkit-box-shadow: 8px 8px 5px -3px rgba(158,158,158,0.92)
    -moz-box-shadow: 8px 8px 5px -3px rgba(158,158,158,0.92)
    border: 2px solid $main-color
    width: 25%
    height: 25%
    border-radius: 10px
    display: flex
    flex-direction: column
    text-align: center
    align-items: center
    justify-content: space-evenly
    min-width: 300px

    h1 
        color: $main-color
        font-size: 20px
    p 
        color: $main-color-light

    button
        width: 30%
        min-width: 175px
