@import "../../../../css-variables/variables.sass"

.addDocModal

    .section-modal
        display: flex
        flex-direction: column
        justify-content: space-between
        border-radius: 5px
        border: 2px solid $secondary-color !important
        width: auto

        .add-section-button-container
            display: flex
            width: 100% !important
            justify-content: space-evenly
            margin: 40px 0 0 0
            align-self: center


            button
                width: 48%


        .animated-add-doc-btn-container
            width: 100%
            display: flex

            .addDocToSection
                width: auto

        .uploading-animated-container
            position: absolute
            width: 85%
            height: 85%
            display: flex
            justify-content: center
            align-items: center

        .animated-container
            width: 100%
            height: 100%
            display: flex
            flex-direction: column
            justify-content: space-between

            #parent-modal-title
                text-align: center

            .Mui-error 
                color: red

            .button-container
                width: 75%
                display: flex
                justify-content: space-evenly
                align-self: center
                
                .delete
                    background: $main-color

                .cancel
                    background: tomato
                    


        .add-section-container
            .button-container
                width: 100% 

        h6 
            font-size: 1rem
            color: $secondary-color
            margin: 10px 0
        

        h2 
            color: $secondary-color
            margin: 5px 0 20px 0
        p
            color: $secondary-color
            margin: 5px 0
        .file-name
            border: 1.25px solid $secondary-color
            margin: 5px 0
            width: 100%
            align-self: center
            display: flex
            justify-content: space-between
            border-radius: 5px
            padding: 5px
            align-items: center

            img 
                width: 25px
                height: 25px
                cursor: pointer

            .file-info-container
                display: flex
                align-items: center

                h6 
                    font-size: 1rem
                    color: $secondary-color

                img 
                    width: 30px
                    height: 30px

.edit-document-modal
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    border-radius: 5px
    border: 2px solid $secondary-color !important
    position: relative
    padding: 30px !important
    min-height: 400px

    .uploading-animated-container
        position: absolute !important

    .animated-container
        width: 100%
        height: 100%

    .change-file
        cursor: pointer

    h2 
        margin: 0 0 20px 0
        color: $secondary-color
        text-align: center

    button
        width: 100%
        margin: 5px 0

    .allow-print-container
        display: flex
        align-items: center
        width: 100%
        margin: 25px 0

        h4 
            color: $secondary-color

    .file-name
        display: flex
        align-items: center
        justify-content: space-between
        width: 100% !important
        border: .5px solid $secondary-color
        border-radius: 5px
        margin: 10px 0
        padding: 10px

        .file-info-container
            display: flex
            align-items: center

        h6 
            color: $secondary-color
            font-size: 1rem
    
    img
        width: 30px
        height: 30px



.document-modal
    width: 350px !important
    display: flex
    flex-direction: column
    justify-content: space-around
    align-items: center
    border-radius: 5px
    border: 2px solid $secondary-color !important
    position: relative

    .alert
        position: static

    .doc-name-info
        width: 75%
        text-align: center
        font-size: 1rem
        margin: 10px 0
        color: $secondary-color
        font-weight: 500

    .name-document
        width: 75%
        margin: 15px 0

    h4 
        color: $secondary-color

    .choose-file
        width: 75%
        margin: 10px 0
        display: flex
        justify-content: center
        align-items: center
        align-self: center

        &::file-selector-button
            border: 1px solid $secondary-color
            border-radius: 2px
            color: $secondary-color
            font-size: 1.125rem
            font-weight: 700
            cursor: pointer


    .upload-document
        margin: 5px

    .file-name
        width: 75%
        border: .5px solid $secondary-color
        margin: 10px 0
        padding: 5px
        border-radius: 5px

        h6 
            color: $secondary-color
            font-size: 1rem

    .drop-files
        height: 100px
        width: 75%
        border: 1px solid $secondary-color
        display: flex
        justify-content: center
        align-items: center
        margin: 5px
        border-radius: 5px

    h2 
        font-size: 1.25rem
        color: $secondary-color



