@import "../../../../css-variables/variables.sass"

header
    height: 75px
    position: fixed !important
    width: 88%
    background: white
    box-shadow: -1px 4px 6px -1px rgba(46,46,46,0.75)
    -webkit-box-shadow: -1px 4px 6px -1px rgba(46,46,46,0.75)
    -moz-box-shadow: -1px 4px 6px -1px rgba(46,46,46,0.75)
    display: flex
    justify-content: space-between
    align-items: center
    padding: 0 10px
    z-index: 7
    margin: 0 0 25px 0

    .page-name-container
        display: flex
        align-items: center

        img 
            width: 30px
            height: 30px
            margin: 0 0 0 10px
            cursor: pointer

        .back-button
            margin: 0 10px


    .header-doc-settings 
        display: flex
        align-items: center
        gap: 10px

        #select-people
            legend
                font-size: 2.5rem !important

        .reset
            background: #e8ba03

    h1 
        font-size: 25px
        text-transform: capitalize
        color: $main-color

        .back-btn
            text-decoration: underline
            cursor: pointer

    .user-container
        display: flex
        align-items: center
        img 
            width: 24px
            height: 24px
            margin: 0 10px

@media screen and (max-width: $laptop-medium-breakpoint) 
    .user-container
        display: none !important
    header 
        width: 100%
    .page-name-container
        h1 
            margin: 0 0 0 75px !important 
        .back-button
            margin: 0 0 0 75px !important
    .header-doc-settings 
        margin: 0 0 0 75px 

@media screen and (max-width: $tablet-small-breakpoint)
    .page-name-container
        h1 
            font-size: 20px !important
        .back-btn
            display: none !important

