.wave {
  z-index: 1;
  position: absolute;
  bottom: -25% !important;
}

.register-card-container {
  width: 30vw;
  height: 80vh;
  min-width: 500px;
  max-width: 650px;
  max-height: 1000px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 2;
  border: 0.5px solid #152D64;
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  background: white;
  align-items: center;
  overflow: hidden;
}
.register-card-container .loading-container {
  position: absolute;
  top: 40%;
  bottom: 40%;
  width: 60px;
  height: 60px;
}
.register-card-container .loading-container svg {
  width: 100%;
  height: 100%;
  stroke: #0138b0;
}
.register-card-container .animation-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}
.register-card-container .animation-container .registerBtn {
  width: 25%;
  align-self: center;
  background-color: #0138b0;
}
.register-card-container .animation-container .already-member-container {
  display: flex;
  align-items: center;
  margin: 50px 0 0 0;
}
.register-card-container .animation-container .already-member-container a {
  font-weight: 700;
  color: #0138b0;
  margin: 0 0 0 5px;
}
.register-card-container .animation-container .already-member-container h6 {
  font-size: 0.75rem;
  color: #0138b0;
}
.register-card-container .animation-container form {
  display: flex;
  flex-direction: column;
  height: 65%;
  width: 60%;
  display: flex;
  flex-direction: column;
  gap: 50px;
}
.register-card-container .animation-container form .error {
  background: rgba(255, 0, 0, 0.4745098039);
  border: 2px solid #ff0000;
}
.register-card-container .animation-container form .message {
  width: 65%;
  position: absolute;
  bottom: 28%;
  display: flex;
  border-radius: 5px;
  left: 17.5%;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
}
.register-card-container .animation-container form .message h6 {
  font-size: 1rem;
  color: white;
  font-weight: 400;
}
.register-card-container .animation-container form .message img {
  width: 24px;
  height: 24px;
  margin: 5px 0;
  cursor: pointer;
}
.register-card-container .animation-container form .input-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 80%;
  position: relative;
}
.register-card-container .animation-container form .input-container .register {
  margin: 10px 0;
}
.register-card-container .animation-container form .input-container input {
  outline: #152D64;
}
.register-card-container .animation-container form .input-container a {
  position: absolute;
  bottom: 5%;
  right: 0;
  font-weight: 500;
  color: #0138b0;
}
.register-card-container .animation-container form h1 {
  color: #0138b0;
}
.register-card-container .branding-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px;
}
.register-card-container .branding-container h3 {
  color: #0138b0;
  font-size: 1.5rem;
}
.register-card-container .branding-container img {
  width: 30px;
  height: 30px;
  margin: 0 10px 0 0;
}

.login-cards-container {
  width: 30vw;
  height: 80vh;
  min-width: 500px;
  max-width: 750px;
  max-height: 1000px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  max-height: 900px;
}
.login-cards-container .loading-container {
  position: absolute;
  top: 45%;
  right: 45%;
  transform: translate(45%, -45%);
  width: 60px;
  height: 60px;
  z-index: 3;
}
.login-cards-container .loading-container svg {
  width: 100%;
  height: 100%;
  stroke: #0138b0;
}
.login-cards-container .card {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 98%;
  flex-direction: column;
  position: absolute;
  width: 98%;
  border-radius: 15px;
  background: white;
  border: 0.5px solid #152D64;
}
.login-cards-container .card .animation-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.login-cards-container .forgot-pass-container {
  position: absolute;
  bottom: 0;
  right: 0;
  box-shadow: 4px 4px 9px -3px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 4px 4px 9px -3px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 4px 4px 9px -3px rgba(0, 0, 0, 0.75);
  z-index: 2;
}
.login-cards-container .forgot-pass-container .forgotpassBtn {
  width: 25%;
  align-self: center;
  background-color: #0138b0;
}
.login-cards-container .forgot-pass-container .loading-container {
  position: absolute;
  top: 40%;
  bottom: 40%;
  width: 60px;
  height: 60px;
  z-index: 3;
}
.login-cards-container .forgot-pass-container .loading-container svg {
  width: 100%;
  height: 100%;
  stroke: #0138b0;
}
.login-cards-container .forgot-pass-container .input-container a {
  bottom: 22% !important;
}
.login-cards-container .login-card-container {
  box-shadow: -4px -3px 7px -2px rgba(82, 82, 82, 0.75);
  -webkit-box-shadow: -4px -3px 7px -2px rgba(82, 82, 82, 0.75);
  -moz-box-shadow: -4px -3px 7px -2px rgba(82, 82, 82, 0.75);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}
.login-cards-container .login-card-container .loginBtn {
  width: 25%;
  align-self: center;
  background-color: #0138b0;
}
.login-cards-container .visible {
  z-index: 3;
}
.login-cards-container .visible h1 {
  color: #0138b0;
}
.login-cards-container .visible form {
  display: flex;
  flex-direction: column;
  height: 65%;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.login-cards-container .visible form .message {
  position: absolute;
  border-radius: 5px;
  border: 2px solid #0138b0;
  display: flex;
  align-items: center;
  background: rgba(168, 1, 1, 0.462745098);
}
.login-cards-container .visible form .message img {
  width: 24px;
  height: 24px;
}
.login-cards-container .visible form .input-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 80%;
  position: relative;
  margin: 100px 0;
  gap: 20px;
}
.login-cards-container .visible form .input-container input {
  outline: #152D64;
}
.login-cards-container .visible form .input-container a {
  font-weight: 500;
  color: #0138b0;
}
.login-cards-container .visible .branding-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px;
}
.login-cards-container .visible .branding-container h3 {
  color: #0138b0;
  font-size: 1.5rem;
}
.login-cards-container .visible .branding-container img {
  width: 30px;
  height: 30px;
  margin: 0 10px 0 0;
}

.invalid-register-key {
  background: white;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  padding: 25px;
  box-shadow: -4px -3px 7px -2px rgba(82, 82, 82, 0.75);
  -webkit-box-shadow: -4px -3px 7px -2px rgba(82, 82, 82, 0.75);
  -moz-box-shadow: -4px -3px 7px -2px rgba(82, 82, 82, 0.75);
}
.invalid-register-key .expired-register-info-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.invalid-register-key .expired-register-info-container h2 {
  color: #466FFD;
  margin: 0 10px;
}
.invalid-register-key .expired-register-info-container img {
  width: 24px;
  height: 24px;
}

@media screen and (max-height: 900px) {
  .register-card-container button, .register-card-container .already-member-container {
    margin-top: 50px;
  }
}
@media screen and (max-height: 800px) {
  .register-card-container button, .register-card-container .already-member-container {
    margin-top: 75px;
  }
}
@media screen and (max-height: 750px) {
  .register-card-container button, .register-card-container .already-member-container {
    margin-top: 100px;
  }
}
@media screen and (max-height: 650px) {
  .login-cards-container {
    width: 100vw;
    height: 100vh;
  }
}
@media screen and (max-width: 600px) {
  .register-card-container {
    width: 100vw;
    height: 100vh;
  }
  .login-cards-container {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    min-width: 100vw;
  }
  .login-cards-container .card {
    width: 100%;
    height: 100%;
    border-radius: 0;
    top: 0 !important;
    left: 0 !important;
  }
  .login-cards-container .card .animation-container {
    align-items: center;
  }
  .login-cards-container .card .animation-container form {
    width: 75%;
  }
  .login-cards-container .card .animation-container button {
    width: 80%;
    max-width: 250px;
  }
}/*# sourceMappingURL=styles.css.map */